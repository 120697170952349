import React, { useState } from 'react';
import '../AiForAll.css';

const AiForAll = () => {
  const [expandedCard, setExpandedCard] = useState(null);

  const toggleCard = (cardId) => {
    setExpandedCard(expandedCard === cardId ? null : cardId);
  };

  return (
    <div className="AiForAll">
      <div className="hero-section">
        <div className="hero-content">
          <h1>Empowering Startups with AI</h1>
          <p>Explore our AI democratization initiatives and exclusive offers for emerging businesses.</p>
          <a href="#services" className="cta-button">Learn More</a>
        </div>
      </div>

      {/* Expandable Cards Section */}
      <div className="info-container" id="services">
        {/* AI Democratization Card */}
        <div 
          className={`info-card ai-democratization ${expandedCard === 'ai-democratization' ? 'expanded' : ''}`}
          onClick={() => toggleCard('ai-democratization')}
        >
          <div className="card-header">
            <i className="fas fa-globe icon"></i>
            <div>
              <h3>AI Democratization</h3>
              <p className="cardtext">Making advanced AI accessible to all businesses.</p>
              <p className="cardcta">Learn More</p>
            </div>
          </div>
          {expandedCard === 'ai-democratization' && <div className="card-expanded-content"><div>AI Democratization aims to make cutting-edge AI technology accessible to businesses of all sizes, especially startups and small enterprises. This initiative helps in leveling the playing field and enabling innovation at every level of business.</div><a href="/questionnaire" className="cta-button">Discover More</a></div>}
        </div>

        {/* Free AI Package for Startups Card */}
        <div 
          className={`info-card free-ai-package ${expandedCard === 'free-ai-package' ? 'expanded' : ''}`}
          onClick={() => toggleCard('free-ai-package')}
        >
          <div className="card-header">
            <i className="fas fa-rocket icon"></i>
            <div>
              <h3>Free AI Package for Startups</h3>
              <p className="cardtext">Exclusive AI tools and resources for new businesses.</p>
              <p className="cardcta">Learn More</p>
            </div>
          </div>
          {expandedCard === 'free-ai-package' && 
          <div className="card-expanded-content"><div>Our exclusive offer for startups includes a free AI package that encompasses tools, resources, and consultations to help integrate AI into your business processes effectively.</div><a href="/questionnaire" className="cta-button">Discover More</a></div>}
        </div>

        {/* AI Education and Training Card */}
        <div 
          className={`info-card ai-education ${expandedCard === 'ai-education' ? 'expanded' : ''}`}
          onClick={() => toggleCard('ai-education')}
        >
          <div className="card-header">
            <i className="fas fa-book icon"></i>
            <div>
              <h3>AI Education and Training</h3>
              <p className="cardtext">Learn about AI applications and their benefits.</p>
              <p className="cardcta">Learn More</p>
            </div>
          </div>
          {expandedCard === 'ai-education' && <div className="card-expanded-content"><div>Our AI education and training programs are designed to provide knowledge and skills necessary for leveraging AI in business settings, making the technology more approachable and understandable.</div><a href="/questionnaire" className="cta-button">Discover More</a></div>}
        </div>

        {/* Community Support and Networking Card */}
        <div 
          className={`info-card community-support ${expandedCard === 'community-support' ? 'expanded' : ''}`}
          onClick={() => toggleCard('community-support')}
        >
          <div className="card-header">
            <i className="fas fa-users icon"></i>
            <div>
              <h3>Community Support</h3>
              <p className="cardtext">Connect and grow with a network of AI enthusiasts.</p>
              <p className="cardcta">Learn More</p>
            </div>
          </div>
          {expandedCard === 'community-support' && <div className="card-expanded-content"><div>Join our community of AI enthusiasts, startups, and experts to share knowledge, collaborate on projects, and find support in your AI journey.<a href="/questionnaire" className="cta-button">Discover More</a></div></div>}
        </div>
      </div>

      <div className="about-us-section about-card">
        <div className="about-content">
          <h2>Why Choose Our AI Solutions?</h2>
          <p className="cardtext">At ASEGEN AI, we are dedicated to providing not just technology, but also the knowledge and support necessary to implement AI successfully in your business. Whether you're a startup or an established enterprise, our AI solutions are designed to be scalable, effective, and transformative.</p>
          <a href="/questionnaire" className="cta-button">Discover More</a>
        </div>
      </div> 
    </div>
  );
};

export default AiForAll;
