import React, { useState } from 'react';
import '../AISecurity.css';

const AISecurity = () => {
  const [expandedCard, setExpandedCard] = useState(null);

  const toggleCard = (cardId) => {
    setExpandedCard(expandedCard === cardId ? null : cardId);
  };

  return (
    <div className="AISecurity">
      <div className="hero-section">
        <div className="hero-content">
          <h1>Enhance Your Security with Advanced AI Solutions</h1>
          <p>Explore our robust AI security services designed to protect your digital assets.</p>
          <a href="#services" className="cta-button">Learn More</a>
        </div>
      </div>

      {/* Security Cards Section */}
      <div className="security-container" id="services">
        {/* Threat Detection Card */}
        <div 
          className={`security-card threat-detection ${expandedCard === 'threat-detection' ? 'expanded' : ''}`}
          onClick={() => toggleCard('threat-detection')}
        >
          <div className="card-header">
            <i className="fas fa-exclamation-triangle icon"></i>
            <div>
              <h3>Threat Detection</h3>
              <p className="cardtext">AI-powered threat detection for proactive security.</p>
              <p className="cardcta">Learn More</p>
            </div>
          </div>
          {expandedCard === 'threat-detection' && 
            <div className="card-expanded-content">
              <div>Our AI-driven threat detection systems proactively identify potential security threats, allowing for immediate response and prevention of security breaches.</div>
              <a href="/questionnaire" className="cta-button">Discover More</a>
            </div>}
        </div>

        {/* Data Protection Card */}
        <div 
          className={`security-card data-protection ${expandedCard === 'data-protection' ? 'expanded' : ''}`}
          onClick={() => toggleCard('data-protection')}
        >
          <div className="card-header">
            <i className="fas fa-lock icon"></i>
            <div>
              <h3>Data Protection</h3>
              <p className="cardtext">Advanced AI algorithms to safeguard your sensitive data.</p>
              <p className="cardcta">Learn More</p>
            </div>
          </div>
          {expandedCard === 'data-protection' && 
            <div className="card-expanded-content">
              <div>Leverage AI to protect sensitive data from unauthorized access and data breaches. Our AI security systems ensure the integrity and confidentiality of your data.</div>
              <a href="/questionnaire" className="cta-button">Discover More</a>
            </div>}
        </div>

        {/* Compliance Monitoring Card */}
        <div 
          className={`security-card compliance-monitoring ${expandedCard === 'compliance-monitoring' ? 'expanded' : ''}`}
          onClick={() => toggleCard('compliance-monitoring')}
        >
          <div className="card-header">
            <i className="fas fa-gavel icon"></i>
            <div>
              <h3>Compliance Monitoring</h3>
              <p className="cardtext">Ensure regulatory compliance with AI-powered monitoring.</p>
              <p className="cardcta">Learn More</p>
            </div>
          </div>
          {expandedCard === 'compliance-monitoring' && 
            <div className="card-expanded-content">
              <div>Our AI solutions continuously monitor compliance with regulations, helping you to avoid legal penalties and maintain regulatory standards.</div>
              <a href="/questionnaire" className="cta-button">Discover More</a>
            </div>}
        </div>

        {/* Network Security Card */}
        <div 
          className={`security-card network-security ${expandedCard === 'network-security' ? 'expanded' : ''}`}
          onClick={() => toggleCard('network-security')}
        >
          <div className="card-header">
            <i className="fas fa-network-wired icon"></i>
            <div>
              <h3>Network Security</h3>
              <p className="cardtext">Robust AI systems to secure your network infrastructure.</p>
              <p className="cardcta">Learn More</p>
            </div>
          </div>
          {expandedCard === 'network-security' && 
            <div className="card-expanded-content">
              <div>Our AI network security tools provide real-time protection against network threats, ensuring the security and resilience of your IT infrastructure.</div>
              <a href="/questionnaire" className="cta-button">Discover More</a>
            </div>}
        </div>
      </div>

      {/* Additional AISecurity Information Section */}
      <div className="about-us-section about-card">
        <div className="about-content">
          <h2>Secure Your Digital World with AI</h2>
          <p className="cardtext">At ASEGEN AI, our AI-driven security solutions are designed to protect your business from the evolving landscape of digital threats. Discover how our innovative approach to security can safeguard your data, systems, and network.</p>
        </div>
      </div> 
    </div>
  );
};

export default AISecurity;
