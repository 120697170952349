import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Layout.css";
import logo from './images/ag-logo-eye-nav.png';
import comp from './images/logo-text.png';

const Layout = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    setIsSpinning(!isSpinning); // Toggle the spinning state
  };

  return (
    <div>
      <nav className="navbar">
        <Link to="/" className=""><img src={logo} alt="ASEGEN AI Logo" className="navbar-logo" /></Link>        
        <img src={comp} alt="ASEGEN AI Logo Text" className="navbar-comp" />
        <div className={`hamburger ${isSpinning ? "spin" : ""}`} onClick={toggleMenu}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
        <div className={`menu ${isOpen ? "open" : ""}`}>
          <Link to="/" onClick={toggleMenu}><i className="fas fa-home"></i> Home</Link>
          <Link to="/ai-automation" onClick={toggleMenu}><i className="fas fa-robot"></i> AI Automation</Link>
          <Link to="/ai-for-all" onClick={toggleMenu}><i className="fas fa-globe"></i> AI for All</Link>
          <Link to="/ai-aas-cloud" onClick={toggleMenu}><i className="fas fa-cloud"></i> AIaaS Cloud</Link>
          <Link to="/generative-ai" onClick={toggleMenu}><i className="fas fa-lightbulb"></i> Generative AI</Link>
          <Link to="/sllm-solutions" onClick={toggleMenu}><i className="fas fa-language"></i> sLLM Solutions</Link>
          <Link to="/ai-security" onClick={toggleMenu}><i className="fas fa-shield-alt"></i> AI Security</Link>
          <Link to="/contact" onClick={toggleMenu}><i className="fas fa-envelope"></i> Contact</Link>
        </div>
        <div className={`menu-overlay ${isOpen ? 'open' : ''}`} onClick={toggleMenu} />
      </nav>
      <main>{children}</main>
      <footer>
        <div className="footer-nav">
          <Link to="/" className="footer-icon"><i className="fas fa-home"></i><span>Home</span></Link>
          <Link to="/blog" className="footer-icon"><i className="fas fa-blog"></i><span>Blog</span></Link>
          <Link to="/videos" className="footer-icon"><i className="fas fa-video"></i><span>Videos</span></Link>
          <Link to="/resources" className="footer-icon"><i className="fas fa-book"></i><span>Resources</span></Link>
          <Link to="/contact" className="footer-icon"><i className="fas fa-envelope"></i><span>Contact</span></Link>
        </div>
      </footer>
    </div>
  );
};

export default Layout;
