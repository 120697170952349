import React, { useState } from 'react';
import '../AIaaS.css';

const AIaaS = () => {
  const [expandedCard, setExpandedCard] = useState(null);

  const toggleCard = (cardId) => {
    setExpandedCard(expandedCard === cardId ? null : cardId);
  };

  return (
    <div className="AIaaS">
      <div className="hero-section">
        <div className="hero-content">
          <h1>Revolutionize Your Business with AIaaS Cloud Solutions</h1>
          <p>Explore our cutting-edge cloud-based AI solutions designed to enhance your business operations.</p>
          <a href="#services" className="cta-button">Learn More</a>
        </div>
      </div>

      {/* Cloud Cards Section */}
      <div className="cloud-container" id="services">
        {/* Scalability Card */}
        <div 
          className={`cloud-card scalability ${expandedCard === 'scalability' ? 'expanded' : ''}`}
          onClick={() => toggleCard('scalability')}
        >
          <div className="card-header">
            <i className="fas fa-arrows-alt-v icon"></i>
            <div>
              <h3>Scalability</h3>
              <p className="cardtext">Effortlessly scale your AI solutions with our cloud infrastructure.</p>
              <p className="cardcta">Learn More</p>
            </div>
          </div>
          {expandedCard === 'scalability' && <div className="card-expanded-content"><div>Our cloud platform provides scalable AI solutions, allowing your business to grow without the limitations of traditional infrastructure. Adapt seamlessly to changing demands with flexible and scalable AIaaS offerings.</div><a href="/questionairre" className="cta-button">Discover More</a></div>}
        </div>

        {/* Cost-Effectiveness Card */}
        <div 
          className={`cloud-card cost-effective ${expandedCard === 'cost-effective' ? 'expanded' : ''}`}
          onClick={() => toggleCard('cost-effective')}
        >
          <div className="card-header">
            <i className="fas fa-tag icon"></i>
            <div>
              <h3>Cost-Effectiveness</h3>
              <p className="cardtext">Reduce operational costs with our efficient AIaaS solutions.</p>
              <p className="cardcta">Learn More</p>
            </div>
          </div>
          {expandedCard === 'cost-effective' && <div className="card-expanded-content"><div>Leverage the power of AI without the high upfront investment. Our AIaaS solutions offer cost-effective access to advanced AI technologies, significantly reducing your operational costs and enhancing ROI.</div><a href="/questionairre" className="cta-button">Discover More</a></div>}
        </div>

        {/* Accessibility Card */}
        <div 
          className={`cloud-card accessibility ${expandedCard === 'accessibility' ? 'expanded' : ''}`}
          onClick={() => toggleCard('accessibility')}
        >
          <div className="card-header">
            <i className="fas fa-universal-access icon"></i>
            <div>
              <h3>Accessibility</h3>
              <p className="cardtext">Access AI capabilities from anywhere, anytime with our cloud services.</p>
              <p className="cardcta">Learn More</p>
            </div>
          </div>
          {expandedCard === 'accessibility' && <div className="card-expanded-content"><div>Our cloud-based AI solutions provide unparalleled accessibility. Access advanced AI tools and services from anywhere in the world, enabling your team to work more flexibly and efficiently.</div><a href="/questionairre" className="cta-button">Discover More</a></div>}
        </div>

        {/* Security & Compliance Card */}
        <div 
          className={`cloud-card security-compliance ${expandedCard === 'security-compliance' ? 'expanded' : ''}`}
          onClick={() => toggleCard('security-compliance')}
        >
          <div className="card-header">
            <i className="fas fa-shield-alt icon"></i>
            <div>
              <h3>Security & Compliance</h3>
              <p className="cardtext">Ensure data security and compliance with our robust cloud infrastructure.</p>
              <p className="cardcta">Learn More</p>
            </div>
          </div>
          {expandedCard === 'security-compliance' && <div className="card-expanded-content"><div>Our AIaaS Cloud prioritizes security and compliance, ensuring your data is protected with the highest security standards. Stay compliant with industry regulations while benefiting from secure AI cloud services.</div><a href="/questionairre" className="cta-button">Discover More</a></div>}
        </div>
      </div>

      {/* Additional AIaaS Information Section */}
      <div className="about-us-section about-card">
        <div className="about-content">
          <h2>Embrace the Cloud, Empower Your AI</h2>
          <a href="/questionairre" className="cta-button">Discover More</a>
        </div>
      </div>
    </div>
  );
};

export default AIaaS;
