import React, { useState } from 'react';
import '../AiAutomation.css';

const AiAutomation = () => {
  const [expandedCard, setExpandedCard] = useState(null);

  const toggleCard = (cardId) => {
    setExpandedCard(expandedCard === cardId ? null : cardId);
  };

  return (
    <div className="AiAutomation">
      <div className="hero-section">
        <div className="hero-content">
          <h1>Unlock the Power of AI Automation for Your Business</h1>
          <p>Discover how our AI automation solutions can streamline your business processes, reduce costs, and enhance efficiency.</p>
          <a href="#services" className="cta-button">Learn More</a>
        </div>
      </div>

      {/* Expandable Cards Section */}
      <div className="auto-container" id="services">
        {/* Custom AI Automation Service Card */}
        <div
          className={`aiauto-card customai-card ${expandedCard === 'custom-ai' ? 'expanded' : ''}`}
          onClick={() => toggleCard('custom-ai')}
        >
          <div className="card-header">
            <i className="fas fa-cogs icon"></i>
            <div>
              <h3>Custom AI Solutions</h3>
              <p className="cardtext">Tailored AI systems designed for your unique business needs.</p>
              <p className="cardcta">Learn More</p>
            </div>
          </div>
          {expandedCard === 'custom-ai' && <div className="card-expanded-content"><div>Custom AI Solutions offer bespoke artificial intelligence systems, specifically designed to meet the unique requirements of your business. From automating repetitive tasks to providing advanced analytics, these solutions are tailored to optimize your operational efficiency and strategic insights.</div><a href="/questionnaire" className="cta-button">Discover More</a></div>}
        </div>

        {/* Process Automation Card */}
        <div
          className={`aiauto-card proauto-card ${expandedCard === 'process-automation' ? 'expanded' : ''}`}
          onClick={() => toggleCard('process-automation')}
        >
          <div className="card-header">
            <i className="fas fa-sync-alt icon"></i>
            <div>
              <h3>Process Automation</h3>
              <p className="cardtext">Automate and optimize your business workflows for maximum efficiency.</p>
              <p className="cardcta">Learn More</p>
            </div>
          </div>
          {expandedCard === 'process-automation' && <div className="card-expanded-content"><div>Process Automation leverages AI to automate complex business workflows, enhancing operational efficiency. By streamlining these processes, companies can reduce manual errors, increase productivity, and free up valuable resources for more strategic tasks.</div><a href="/questionnaire" className="cta-button">Discover More</a></div>}
        </div>

        {/* AI Integration Card */}
        <div
          className={`aiauto-card aiint-card ${expandedCard === 'ai-integration' ? 'expanded' : ''}`}
          onClick={() => toggleCard('ai-integration')}
        >
          <div className="card-header">
            <i className="fas fa-network-wired icon"></i>
            <div>
              <h3>AI Integration</h3>
              <p className="cardtext">Seamlessly integrate AI capabilities into your existing systems.</p>
              <p className="cardcta">Learn More</p>
            </div>
          </div>
          {expandedCard === 'ai-integration' && <div className="card-expanded-content"><div>AI Integration involves embedding artificial intelligence into your existing technological infrastructure. This strategic integration enhances system capabilities, allowing for smarter and more efficient decision-making processes.</div><a href="/questionnaire" className="cta-button">Discover More</a></div>}
        </div>

        {/* CRM Automation Card */}
        <div
          className={`aiauto-card crm-auto-card ${expandedCard === 'crm-automation' ? 'expanded' : ''}`}
          onClick={() => toggleCard('crm-automation')}
        >
          <div className="card-header">
            <i className="fas fa-user-friends icon"></i>
            <div>
              <h3>CRM Automation</h3>
              <p className="cardtext">Enhance customer relationships with AI-driven CRM solutions.</p>
              <p className="cardcta">Learn More</p>
            </div>
          </div>
          {expandedCard === 'crm-automation' && <div className="card-expanded-content"><div>CRM Automation uses AI to revolutionize customer relationship management. By analyzing customer data, AI can provide insights into customer behavior, enhance personalization, and streamline communication, leading to improved customer satisfaction and loyalty.</div><a href="/questionnaire" className="cta-button">Discover More</a></div>}
        </div>

        {/* Data Analysis Automation Card */}
        <div
          className={`aiauto-card data-analysis-card ${expandedCard === 'data-analysis' ? 'expanded' : ''}`}
          onClick={() => toggleCard('data-analysis')}
        >
          <div className="card-header">
            <i className="fas fa-chart-bar icon"></i>
            <div>
              <h3>Data Analysis Automation</h3>
              <p className="cardtext">Automated insights from your data for informed decision-making.</p>
              <p className="cardcta">Learn More</p>
            </div>
          </div>
          {expandedCard === 'data-analysis' && <div className="card-expanded-content"><div>Data Analysis Automation leverages AI to extract meaningful insights from vast amounts of data. This automated analysis identifies trends, forecasts future scenarios, and aids in making data-driven decisions that are crucial for business growth and strategy.</div><a href="/questionnaire" className="cta-button">Discover More</a></div>}
        </div>

        {/* Predictive Maintenance Automation Card */}
        <div
          className={`aiauto-card predictive-maintenance-card ${expandedCard === 'predictive-maintenance' ? 'expanded' : ''}`}
          onClick={() => toggleCard('predictive-maintenance')}
        >
          <div className="card-header">
            <i className="fas fa-tools icon"></i>
            <div>
              <h3>Predictive Maintenance</h3>
              <p className="cardtext">AI-driven predictive maintenance for operational efficiency.</p>
              <p className="cardcta">Learn More</p>
            </div>
          </div>
          {expandedCard === 'predictive-maintenance' && <div className="card-expanded-content"><div>Predictive Maintenance employs AI algorithms to predict equipment failures before they happen, allowing for timely maintenance. This proactive approach reduces downtime, saves costs, and increases the lifespan of equipment.</div><a href="/questionnaire" className="cta-button">Discover More</a></div>}
        </div>
      </div>

      {/* Additional AI Automation Information */}
      <div className="about-us-section about-card">
        <div className="about-content">
          <h2>Why AI Automation?</h2>
          <p className="cardtext">At ASEGEN AI, we specialize in harnessing the power of artificial intelligence to revolutionize business processes. Our approach integrates cutting-edge AI technology to automate complex operations, enhancing efficiency and innovation. With a focus on AI democratization, we strive to make advanced AI solutions accessible to businesses of all sizes. Join us on our mission to shape the future of business automation.</p>
        </div>
      </div>
    </div>
  );
};

export default AiAutomation;
