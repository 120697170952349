import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './Layout';
import './App.css';
import Home from './pages/Home';
import AiAutomation from './pages/AiAutomation';
import AIForAll from './pages/AIForAll';
import AIaaS from './pages/AIaaS';
import GenerativeAI from './pages/GenerativeAI';
import SLLMSolutions from './pages/SLLMSolutions';
import AISecurity from './pages/AISecurity';
import Blog from './pages/Blog';
import Videos from './pages/Videos';
import Resources from './pages/Resources';
import Contact from './pages/Contact';
import Questionnaire from './pages/Questionnaire';

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ai-automation" element={<AiAutomation />} />
          <Route path="/ai-for-all" element={<AIForAll />} />
          <Route path="/ai-aas-cloud" element={<AIaaS />} />
          <Route path="/generative-ai" element={<GenerativeAI />} />
          <Route path="/sllm-solutions" element={<SLLMSolutions />} />
          <Route path="/ai-security" element={<AISecurity />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/videos" element={<Videos />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/questionnaire" element={<Questionnaire />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;