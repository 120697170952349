import React, { useState } from 'react';
import '../SLLMSolutions.css';

const SLLMSolutions = () => {
  const [expandedCard, setExpandedCard] = useState(null);

  const toggleCard = (cardId) => {
    setExpandedCard(expandedCard === cardId ? null : cardId);
  };

  return (
    <div className="SLLMSolutions">
      <div className="hero-section">
        <div className="hero-content">
          <h1>Small Language Model Solutions</h1>
          <p>Optimize your business with tailored small language model solutions.</p>
        </div>
      </div>

      <div className="sllm-container">
        {/* Tailored Language Models Card */}
        <div 
          className={`sllm-card tailored-lm ${expandedCard === 'tailored-lm' ? 'expanded' : ''}`}
          onClick={() => toggleCard('tailored-lm')}
        >
          <div className="card-header">
            <i className="fas fa-brain icon"></i>
            <div>
              <h3>Tailored Language Models</h3>
              <p className="cardtext">Custom language models for your specific business needs.</p>
              <p className="cardcta">Learn More</p>
            </div>
          </div>
          {expandedCard === 'tailored-lm' && <div className="card-expanded-content"><div>Explore our custom-tailored language models designed to suit your business’s unique communication and data processing needs, enhancing efficiency and accuracy.</div><a href="/questionnaire" className="cta-button">Discover More</a></div>}
        </div>

        {/* NLP for Business Analytics Card */}
        <div 
          className={`sllm-card nlp-ba ${expandedCard === 'nlp-ba' ? 'expanded' : ''}`}
          onClick={() => toggleCard('nlp-ba')}
        >
          <div className="card-header">
            <i className="fas fa-chart-line icon"></i>
            <div>
              <h3>NLP for Business Analytics</h3>
              <p className="cardtext">Harness the power of NLP to derive insights from business data.</p>
            </div>
          </div>
          {expandedCard === 'nlp-ba' && <div className="card-expanded-content"><div>Utilize natural language processing (NLP) to analyze business data, extract insights, and make informed decisions based on comprehensive data interpretation.</div><a href="/questionnaire" className="cta-button">Discover More</a></div>}
        </div>

        {/* Chatbot Solutions Card */}
        <div 
          className={`sllm-card chatbot-sol ${expandedCard === 'chatbot-sol' ? 'expanded' : ''}`}
          onClick={() => toggleCard('chatbot-sol')}
        >
          <div className="card-header">
            <i className="fas fa-comments icon"></i>
            <div>
              <h3>Chatbot Solutions</h3>
              <p className="cardtext">Improve customer engagement with AI-powered chatbots.</p>
              <p className="cardcta">Learn More</p>
            </div>
          </div>
          {expandedCard === 'chatbot-sol' && <div className="card-expanded-content">
            <div>Enhance customer service and engagement with our AI-powered chatbot solutions, offering personalized interactions and support.</div>
            <a href="/questionnaire" className="cta-button">Discover More</a>
          </div>}
        </div>

        {/* Language Model Integration Card */}
        <div 
          className={`sllm-card lm-integration ${expandedCard === 'lm-integration' ? 'expanded' : ''}`}
          onClick={() => toggleCard('lm-integration')}
        >
          <div className="card-header">
            <i className="fas fa-cogs icon"></i>
            <div>
              <h3>Language Model Integration</h3>
              <p className="cardtext">Seamlessly integrate small language models into your existing systems.</p>
              <p className="cardcta">Learn More</p>
            </div>
          </div>
          {expandedCard === 'lm-integration' && 
          <div className="card-expanded-content">
            <div>Our services include the seamless integration of small language models into your current systems, enhancing your existing tech stack with the power of AI.</div>
            <a href="/questionnaire" className="cta-button">Discover More</a>
          </div>}
        </div>
      </div>

      <div className="about-us-section about-card">
        <div className="about-content">
          <h2>Advancing Communication with AI</h2>
          <p className="cardtext">At ASEGEN AI, we leverage small language models to revolutionize the way businesses interact and process information. From enhancing customer communication to automating data analysis, our solutions bring cutting-edge AI to the forefront of your business operations.</p>
          <a href="/questionnaire" className="cta-button">Discover More</a>
        </div>
      </div> 
    </div>
  );
};

export default SLLMSolutions;
