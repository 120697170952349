import React, { useState } from 'react';
import '../GenerativeAI.css';

const GenerativeAI = () => {
  const [expandedCard, setExpandedCard] = useState(null);

  const toggleCard = (cardId) => {
    setExpandedCard(expandedCard === cardId ? null : cardId);
  };

  return (
    <div className="GenerativeAI">
      <div className="hero-section">
        <div className="hero-content">
          <h1>Revolutionize Content Creation with Generative AI</h1>
          <p>Unleash the potential of AI to transform your creative processes.</p>
          <a href="#services" className="cta-button">Learn More</a>
        </div>
      </div>

      {/* Expandable Cards Section */}
      <div className="info-container" id="services">
        {/* Creative AI Solutions Card */}
        <div 
          className={`info-card creative-ai ${expandedCard === 'creative-ai' ? 'expanded' : ''}`}
          onClick={() => toggleCard('creative-ai')}
        >
          <div className="card-header">
            <i className="fas fa-palette icon"></i>
            <div>
              <h3>Creative AI Solutions</h3>
              <p className="cardtext">Harness AI for innovative and efficient content creation.</p>
              <p className="cardcta">Learn More</p>
            </div>
          </div>
          {expandedCard === 'creative-ai' && <div className="card-expanded-content"><div>Creative AI Solutions leverage generative models to create novel and engaging content. From artwork to written content, these AI tools can aid in creative processes and spark new ideas.</div><a href="/questionnaire" className="cta-button">Discover More</a></div>}
        </div>

        {/* AI-Assisted Design Card */}
        <div 
          className={`info-card ai-design ${expandedCard === 'ai-design' ? 'expanded' : ''}`}
          onClick={() => toggleCard('ai-design')}
        >
          <div className="card-header">
            <i className="fas fa-ruler-combined icon"></i>
            <div>
              <h3>AI-Assisted Design</h3>
              <p className="cardtext">Empower your design process with AI insights and automation.</p>
              <p className="cardcta">Learn More</p>
            </div>
          </div>
          {expandedCard === 'ai-design' && <div className="card-expanded-content"><div>AI-Assisted Design tools help designers by automating routine tasks, generating design options, and providing insights based on data analysis, thus enhancing creativity and efficiency.</div><a href="/questionnaire" className="cta-button">Discover More</a></div>}
        </div>

        {/* Automated Content Generation Card */}
        <div 
          className={`info-card content-generation ${expandedCard === 'content-generation' ? 'expanded' : ''}`}
          onClick={() => toggleCard('content-generation')}
        >
          <div className="card-header">
            <i className="fas fa-robot icon"></i>
            <div>
              <h3>Automated Content Generation</h3>
              <p className="cardtext">Create high-quality content quickly with AI-driven tools.</p>
              <p className="cardcta">Learn More</p>
            </div>
          </div>
          {expandedCard === 'content-generation' && <div className="card-expanded-content"><div>Automated Content Generation uses AI to create content such as articles, reports, and even poetry. This technology can mimic various writing styles and adapt to specific content needs.</div><a href="/questionnaire" className="cta-button">Discover More</a></div>}
        </div>

        {/* AI in Entertainment and Media Card */}
        <div 
          className={`info-card entertainment-media ${expandedCard === 'entertainment-media' ? 'expanded' : ''}`}
          onClick={() => toggleCard('entertainment-media')}
        >
          <div className="card-header">
            <i className="fas fa-film icon"></i>
            <div>
              <h3>AI in Entertainment and Media</h3>
              <p className="cardtext">Explore how AI is transforming storytelling and media production.</p>
              <p className="cardcta">Learn More</p>
            </div>
          </div>
          {expandedCard === 'entertainment-media' && <div className="card-expanded-content"><div>AI in Entertainment and Media is reshaping how stories are told and produced. From scriptwriting assistance to special effects creation, AI is opening new avenues for creativity and innovation in the media industry.</div><a href="/questionnaire" className="cta-button">Discover More</a></div>}
        </div>
      </div>

      {/* Additional Information Section */}
      <div className="about-us-section about-card">
        <div className="about-content">
          <h2>The Future of Creativity with Generative AI</h2>
          <p className="cardtext">Generative AI is not just a tool; it's a collaborator that brings a new dimension to the creative process. Embrace the future of creativity with our advanced AI solutions that promise to enrich and enhance your creative endeavors.</p>
          <a href="/questionnaire" className="cta-button">Discover More</a>
        </div>
      </div> 
    </div>
  );
};

export default GenerativeAI;
