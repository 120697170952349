import React from 'react';
import { Link } from 'react-router-dom';
import '../Home.css';

const Home = () => {
  return (
    <div className="home">
      <div className="services-section">
        <div className="cards-header">
          <h2 className="bounce-text">Select a Service</h2>
        </div>
        <div className="services-container">
        {/* AI Automation Service Card */}
        <Link to="/ai-automation" className="service-card-link">
          <div className="service-card aiautomation">
            <div className="card-header">
              <i className="fas fa-robot icon"></i>
              <div>
                <h3>AI Automation</h3>
                <p class="cardtext">Streamlining business processes automation.</p>
                <p className="cardcta">Learn More</p>
              </div>
            </div>
          </div>
        </Link>

        {/* AI for All Service Card */}
        <Link to="/ai-for-all" className="service-card-link">
          <div className="service-card aiforall">
            <div className="card-header">
              <i className="fas fa-globe icon"></i>
              <div>
                <h3>AI for All</h3>
                <p class="cardtext">Democratizing AI for businesses of all sizes.</p>
                <p className="cardcta">Learn More</p>
              </div>
            </div>
          </div>
        </Link>

        {/* AIaaS Cloud Service Card */}
        <Link to="/ai-aas-cloud" className="service-card-link">
          <div className="service-card aiaascloud">
            <div className="card-header">
              <i className="fas fa-cloud icon"></i>
              <div>
                <h3>AIaaS Cloud</h3>
                <p class="cardtext">Cloud-based AI solutions for efficient processing.</p>
                <p className="cardcta">Learn More</p>
              </div>
            </div>
          </div>
        </Link>

        {/* Generative AI Service Card */}
        <Link to="/generative-ai" className="service-card-link">
          <div className="service-card generativeai">
            <div className="card-header">
              <i className="fas fa-lightbulb icon"></i>
              <div>
                <h3>Generative AI</h3>
                <p class="cardtext">Innovative content creation using AI.</p>
                <p className="cardcta">Learn More</p>
              </div>
            </div>
          </div>
        </Link>

        {/* sLLM Solutions Service Card */}
        <Link to="/sllm-solutions" className="service-card-link">
          <div className="service-card sllmsolutions">
            <div className="card-header">
              <i className="fas fa-language icon"></i>
              <div>
                <h3>sLLM Solutions</h3>
                <p class="cardtext">Small language models tailored for specific needs.</p>
                <p className="cardcta">Learn More</p>
              </div>
            </div>
          </div>
        </Link>

        {/* AI Security Service Card */}
        <Link to="/ai-security" className="service-card-link">
          <div className="service-card aisecurity">
            <div className="card-header">
              <i className="fas fa-shield-alt icon"></i>
              <div>
                <h3>AI Security</h3>
                <p class="cardtext">Ensuring robust security in AI applications.</p>
                <p className="cardcta">Learn More</p>
              </div>
            </div>
          </div>
        </Link>
      </div>
      </div>
      <div className="about-us-section about-card">
        <div className="about-content">
          <h2>About ASEGEN AI</h2>
          <p class="cardtext">At ASEGEN AI, we specialize in harnessing the power of artificial intelligence to revolutionize business processes. Our approach integrates cutting-edge AI technology to automate complex operations, enhancing efficiency and innovation. With a focus on AI democratization, we strive to make advanced AI solutions accessible to businesses of all sizes. Join us on our mission to shape the future of business automation.</p>
        </div>
      </div>     
    </div>
  );
};

export default Home;